import { render, staticRenderFns } from "./BankersTable.vue?vue&type=template&id=97b96994&"
import script from "./BankersTable.vue?vue&type=script&lang=js&"
export * from "./BankersTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('97b96994')) {
      api.createRecord('97b96994', component.options)
    } else {
      api.reload('97b96994', component.options)
    }
    module.hot.accept("./BankersTable.vue?vue&type=template&id=97b96994&", function () {
      api.rerender('97b96994', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/banker/table/BankersTable.vue"
export default component.exports